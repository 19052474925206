<template>
  <page-container>
    <div class="projects-container">
      <h2 class="title">Projects</h2>
      <section class="projects">
        <project-card
          v-for="(project, index) in projects"
          :key="index"
          :project="project"
          class="project-card"
        ></project-card>
      </section>
      <div class="navigation" v-show="totalPages > 1">
        <div
          class="navigation-btn"
          :class="{ 'hide-nav-btn': currentPage === 1 }"
          @click="changePage('prev')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58.402"
            height="33.771"
            viewBox="0 0 58.402 33.771"
            id="left-arr"
          >
            <g transform="translate(3.535 3.535)">
              <path
                d="M-15583-10791.1l27.368,26.631,23.968-26.631"
                transform="translate(15583 10791.1)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-width="5"
              />
            </g>
          </svg>
          <span class="prev">Prev</span>
        </div>
        <div
          class="navigation-btn"
          :class="{ 'hide-nav-btn': currentPage === totalPages }"
          @click="changePage('next')"
        >
          <span class="next">Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58.402"
            height="33.771"
            viewBox="0 0 58.402 33.771"
            id="right-arr"
          >
            <g transform="translate(3.535 3.535)">
              <path
                d="M-15583-10791.1l27.368,26.631,23.968-26.631"
                transform="translate(15583 10791.1)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-width="5"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      pageLimit: 4,
      currentPage: 1,
      startIdx: 0,
    };
  },

  computed: {
    stopIdx() {
      return this.startIdx + this.pageLimit;
    },

    totalPages() {
      return Math.ceil(this.projectsData.length / this.pageLimit);
    },

    projects() {
      return this.projectsData.slice(this.startIdx, this.stopIdx);
    },

    projectsData() {
      return this.PROJECTS();
    },
  },

  methods: {
    ...mapGetters(['PROJECTS']),

    changePage(direction) {
      if (direction === 'next') {
        this.currentPage++;
        this.startIdx += this.pageLimit;
        window.scrollTo(0, 0);
      } else if (direction === 'prev') {
        this.currentPage--;
        this.startIdx -= this.pageLimit;
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.projects-container {
  padding: 9.6rem 0 4rem;
  scroll-behavior: smooth;

  .title {
    font-size: 8.9rem;
    font-family: var(--font-header);
    text-align: center;
    margin-bottom: 5.4rem;
  }

  .projects {
    .project-card {
      height: 100vh;
      padding-right: 9%;

      &:nth-child(even) {
        background: white;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7.8rem;
    margin-top: 4rem;

    @media screen and (max-width: 700px) {
      padding: 0 1rem;
    }
    @media screen and (max-width: 400px) {
      padding: 0 0.5rem;
    }

    &-btn {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        span {
          color: var(--color-text);
        }

        #right-arr,
        #left-arr {
          g {
            path {
              stroke: var(--color-text);
            }
          }
        }
      }

      #right-arr {
        transform: scale(0.4) rotate(-90deg);
        transition: all 0.2s ease;
      }
      #left-arr {
        transform: scale(0.4) rotate(90deg);
        transition: all 0.2s ease;
      }

      span {
        font-size: 1.9rem;
        color: var(--color-dark-grey);
        font-family: var(--font-secondary);
        transition: all 0.2s ease;
      }

      .next {
        margin-right: -1rem;
      }

      .prev {
        margin-left: -1rem;
      }
    }
  }
}

.hide-nav-btn {
  visibility: hidden;
}
</style>